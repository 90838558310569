import { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
// import { Col, Container, Form, Image, Row } from "react-bootstrap";
import {
  Card,
  Form,
  Button,
  Container,
  Row,
  Col,
  Image,
  InputGroup,
} from "react-bootstrap";
import logo from "../img/logo-clinica.png";
import { useNavigate } from "react-router-dom";
import { userContext } from "../context/userContext";
import { loginClinica } from "../services/horariosService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";


import moment from "moment";
import Swal from 'sweetalert2'
import { marcacionEntrada } from "../services/horariosService";

const ModalAsistencia = ({ showModal, hideModal }) => {
  /*const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const { setRolUser } = useContext(userContext);
  let navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (user && pass) {
        const res = await loginClinica(user, pass);
        if (res.data.length > 0) {
          setRolUser(res.data[0].rol);
          navigate("/horarios", { replace: true });
        } else {
          alert("Datos incorrectos");
        }
      } else {
        alert("Ingrese usuario y contraseña");
      }
    } catch (err) {
      console.log(err);
    }
  };*/
  const [dni, setDni] = useState('');
  const [show, setShow] = useState(false);

  let fechaSystem = moment();
  let diaActual = fechaSystem.format("YYYY-MM-DD");
  let horaActual = fechaSystem.format("HH:mm");

  const handleAsistencia = async (e) => {
    e.preventDefault();
    try {
      if (dni) {
        const res = await marcacionEntrada(dni, diaActual);
        if (res) {
          setDni('');
          Swal.fire({
            title: "Listo",
            text: "Se registró la asistencia",
            icon: "success"
          });
        } else {
          alert('error');
        }
      } else {
        alert('Ingrese DNI para marcar asistencia');
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    /*<Container className="pb-4">
      <Card className="border-0">
        <Card.Body className="pb-4 px-4">
          <Row className="justify-content-center mb-4">
            <Col md={8} className="text-center">
              <Image alt="logo-clinica" src={logo} className="img-fluid" />
            </Col>
          </Row>
          <Form>
            <InputGroup className="mb-3 d-flex">
              <Form.Control
                type="text"
                placeholder="Usuario"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                aria-describedby="basic-addon2"
              />
              <span className="input-group-text" style={{color:'#0d6efd'}}>
                <FontAwesomeIcon icon={faUser} />
              </span>
            </InputGroup>
            <InputGroup className="mb-4 d-flex">
              <Form.Control
                type="password"
                placeholder="Contraseña"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
              <span className="input-group-text" style={{color:'#0d6efd'}}>
                <FontAwesomeIcon icon={faLock} />
              </span>
            </InputGroup>
            <Button
              variant="primary"
              className="form-control mb-3"
              onClick={handleLogin}
            >
              Ingresar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>*/
    <Modal show={showModal} onHide={hideModal} centered>
      <Modal.Body>
        <h5 className="text-center mb-3">Sistema de marcacíon de entrada</h5>
        <Form className="text-center mb-3">
          <h1>{horaActual}</h1>
          <Form.Control
            type="text"
            placeholder="DNI"
            className="mb-3"
            value={dni}
            onChange={(e) => { setDni(e.target.value) }}
          />
          <button className="btn form-control btn-primary mb-3" onClick={handleAsistencia}>Marcar asistencia</button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={hideModal}>Cancelar</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAsistencia;
