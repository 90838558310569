import './styles/App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from "./pages/dashboard";
import { Confirmacion } from "./pages/confirmacion";
import Login from './pages/login';
import AsistenciaSinTurno from './pages/asistenciaSinTurno';
import Navbars from './components/Navbar';
import { ConfirmacionTurno } from './pages/confirmacionTurno';
import SoliHorasExtras from './pages/soliHorasExtras';

function AppRouter() {
  return (
    <Router>
      <Routes basename={"/"}>
        <Route path="/" element={<Login />} />
        <Route path="/confirmacion" element={<Confirmacion />} />
        <Route path="/confirmacion-turno" element={<ConfirmacionTurno />} />
        <Route path="/*" element={<AuthenticatedRoutes />} />
      </Routes>
    </Router>
  );
}

function AuthenticatedRoutes() {
  return (
    <>
      <Navbars />
      <Routes>
        <Route path="horarios" element={<Dashboard />} />
        <Route path="/solicitudes-de-asistencias" element={<AsistenciaSinTurno />} />
        <Route path="/solicitudes-de-horas-extras" element={<SoliHorasExtras />} />
      </Routes>
    </>
  );
}
export default AppRouter;
