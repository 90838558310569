import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Form, Row, Table } from "react-bootstrap";
import { getAllAprobacionesTurnos, aprobarHrsDias, putRechazarHrsDias, getSoliHorasExtras, putRechazarHrsExtras, aprobarHrsExtras } from "../services/horariosService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import Swal from 'sweetalert2'


const SoliHorasExtras = () => {

    const [arrData, setArrData] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const currentDate = moment().format("YYYY-MM-DD");
    const [filterDay, setFilterDay] = useState(currentDate);

    const fechaSystem = moment();
    const diaActual = fechaSystem.format("D");
    const mesActual = fechaSystem.format("MM");
    const anioActual = fechaSystem.format("YYYY");

    useEffect(() => {
        (async () => {
            try {
                const res = await getSoliHorasExtras(diaActual, mesActual, anioActual);
                setArrData(res.data);
                setPageLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const aprobar = async (id) => {
        const res = await aprobarHrsExtras(id);
        if (res) {
            const res = await getSoliHorasExtras(diaActual, mesActual, anioActual);
            setArrData(res.data);
            Swal.fire({
                title: "Horas extras aprobadas",
                text: "Contactate con el Cuidador",
                icon: "success"
            });
        } else {
            alert('Error')
        }
    }

    const rechazar = async (id) => {
        const res = await putRechazarHrsExtras(id);
        if (res) {
            const res = await getSoliHorasExtras(diaActual, mesActual, anioActual);
            setArrData(res.data);
            Swal.fire({
                title: "Horas extras rechazadas",
                text: "Contactate con el Cuidador",
                icon: "error"
            });
        } else {
            alert('Error')
        }
    }

    const searchByDay = async (e) => {
        e.preventDefault();
        try {
            if (filterDay) {
                var diaFilterDay = moment(filterDay).format('D');
                var mesFilterDay = moment(filterDay).format('MM');
                var anioFilterDay = moment(filterDay).format('YYYY');
                const res = await getSoliHorasExtras(diaFilterDay, mesFilterDay, anioFilterDay);
                setArrData(res.data);
            } else {
                alert('Seleccione un día')
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Container fluid style={{ background: '#f4f7ff', height: '100vh' }}>

            <Row className="p-3">
                <h5>Solicitudes de horas extras</h5>
                <Form className="mb-3">
                    <Row>
                        <Col md={2}>
                            <Form.Control
                                onChange={(e) => { setFilterDay(e.target.value) }}
                                value={filterDay}
                                type="date"
                                className="mb-3"
                            />
                        </Col>
                        <Col md={3}>
                            <button className="btn btn-primary form-control" onClick={searchByDay}>Ver solicitudes</button>
                        </Col>
                    </Row>
                </Form>
                <Col>
                    {arrData.length > 0 ?
                        <Table responsive striped bordered hover className="text-center">
                            <thead className="table-primary">
                                <tr>
                                    <th>Número de documento</th>
                                    <th>Cuidador</th>
                                    <th>Hrs. Extras solicitadas</th>
                                    <th>Fecha / Hora</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {arrData.map((data, idx) => (
                                    <tr key={idx}>
                                        <td>{data.num_doc}</td>
                                        <td>{data.nombre_personal}</td>
                                        <td>{data.detalle}</td>
                                        <td>{moment(data.fechahora).add(5, 'hours').format('lll')}</td>
                                        <td>{data.estado === 'pendiente' ?
                                            <>
                                                <button className="btn" onClick={() => aprobar(data.id)}><FontAwesomeIcon icon={faCheck} /></button>
                                                <button className="btn" onClick={() => rechazar(data.id)}><FontAwesomeIcon icon={faXmark} /></button>
                                            </>
                                            : <Badge bg={data.estado === 'Si' ? 'success' : 'danger'} className="text-capitalize">{data.estado === 'Si' ? 'Aprobado' : 'Rechazado'}</Badge>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        : 'Sin resultados'
                    }
                </Col>
            </Row>

        </Container >
    )
}

export default SoliHorasExtras;