import React, { useState, useContext } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import { marcacionEntrada } from "../services/horariosService";
import moment from "moment";
import Swal from 'sweetalert2'
import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import { useNavigate } from "react-router-dom";
import { userContext } from "../context/userContext";
import { loginClinica } from "../services/horariosService";
import logo from "../img/logo-clinica.png";
import ModalAsistencia from "../components/ModalAsistencia";


const Login = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /*const [dni, setDni] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    let fechaSystem = moment();
    let diaActual = fechaSystem.format("YYYY-MM-DD");
    let horaActual = fechaSystem.format("HH:mm");

    const handleAsistencia = async (e) => {
        e.preventDefault();
        try {
            if (dni) {
                const res = await marcacionEntrada(dni, diaActual);
                if (res) {
                    setDni('');
                    Swal.fire({
                        title: "Listo",
                        text: "Se registró la asistencia",
                        icon: "success"
                    });
                } else {
                    alert('error');
                }
            } else {
                alert('Ingrese DNI para marcar asistencia');
            }
        } catch (err) {
            console.log(err)
        }
    }*/
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const { setRolUser } = useContext(userContext);
    
    let navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            if (user && pass) {
                const res = await loginClinica(user, pass);
                console.log(res);
                if (res.data.length > 0) {
                    setRolUser(res.data[0]);
                    navigate("/horarios", { replace: true });
                } else {
                    alert("Datos incorrectos");
                }
            } else {
                alert("Ingrese usuario y contraseña");
            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        /*<Container fluid style={{ background: '#f4f7ff', height: '100vh' }}>
            <Row style={{ justifyContent: 'center', alignItems: 'center', height: '70%' }}>
                <Col md={4}>
                    <h5 className="text-center mb-3">Sistema de marcacíon de entrada</h5>
                    <Form className="text-center mb-3">
                        <h1>{horaActual}</h1>
                        <Form.Control
                            type="text"
                            placeholder="DNI"
                            className="mb-3"
                            value={dni}
                            onChange={(e) => { setDni(e.target.value) }}
                        />
                        <button className="btn btn-primary form-control mb-3" onClick={handleAsistencia}>Marcar asistencia</button>
                        <Button variant="link" onClick={handleShow}>Iniciar Sesión</Button>
                        <ModalLogin show={show} onHide={handleClose} />
                    </Form>
                </Col>
            </Row>
    </Container>*/
        <Container fluid className="d-flex align-items-center justify-content-center" style={{ background: 'rgb(244, 247, 255)', height: '100vh' }}>
            <Row>
                <Card className="border-0" style={{ background: 'transparent' }}>
                    <Card.Body>
                        <Row className="justify-content-center mb-4">
                            <Col md={8}>
                                <Image alt="logo-clinica" src={logo} className="img-fluid" />
                            </Col>
                        </Row>
                        <Form>
                            <InputGroup className="mb-3 d-flex">
                                <Form.Control
                                    type="text"
                                    placeholder="Usuario"
                                    value={user}
                                    onChange={(e) => setUser(e.target.value)}
                                    aria-describedby="basic-addon2"
                                />
                                <span className="input-group-text" style={{ color: '#0d6efd' }}>
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                            </InputGroup>
                            <InputGroup className="mb-4 d-flex">
                                <Form.Control
                                    type="password"
                                    placeholder="Contraseña"
                                    value={pass}
                                    onChange={(e) => setPass(e.target.value)}
                                />
                                <span className="input-group-text" style={{ color: '#0d6efd' }}>
                                    <FontAwesomeIcon icon={faLock} />
                                </span>
                            </InputGroup>
                            <Button
                                variant="primary"
                                className="form-control mb-3"
                                onClick={handleLogin}
                            >
                                Ingresar
                            </Button>
                            <div className="text-center">
                                <Button variant="link" onClick={handleShow}>Marcar asistencia</Button>
                            </div>
                            <ModalAsistencia showModal={show} hideModal={handleClose}/>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>
        </Container >
    );
}

export default Login;