import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../img/logo-clinica.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { userContext } from '../context/userContext';

function Navbars() {

    const { rolUser } = useContext(userContext);
    const navigate = useNavigate();

    console.log(rolUser);

    const onLogout = () => {
        navigate('/', {
            replace: true
        });
        localStorage.removeItem('hasShownModal');
        localStorage.removeItem('userRol');
    }

    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/horarios">
                    <img
                        alt="logo-clinica"
                        src={logo}
                        className="d-inline-block align-top"
                        width="200px"
                        height="61px"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className=""> {/**me-auto */}
                    <Nav.Link href="/horarios">Horarios</Nav.Link>
                    <Nav.Link href="/solicitudes-de-asistencias">Solicitudes de asistencias</Nav.Link>
                    <Nav.Link href="/solicitudes-de-horas-extras">Solicitudes de Hrs. Extras</Nav.Link>
                    <button 
                        className="btn p-0 btn-salida" 
                        style={{ color: '#0d6efd' }} 
                        onClick={onLogout}
                    >
                        Salir <FontAwesomeIcon icon={faArrowRightFromBracket} />
                    </button> 
                </Nav>
                </Navbar.Collapse>
                <NavDropdown title={`Bienvenido ${rolUser.nombre}`} id="basic-nav-dropdown">
                    <NavDropdown.Item>
                        <button 
                            className="btn p-0" 
                            style={{ color: '#0d6efd' }} 
                            onClick={onLogout}
                        >
                            Salir <FontAwesomeIcon icon={faArrowRightFromBracket} />
                        </button>
                        </NavDropdown.Item>
                </NavDropdown>
            </Container>
        </Navbar>
    );
}

export default Navbars;