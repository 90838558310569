import React, { useState, useEffect } from 'react'
import { userContext } from './userContext';

const UserComponent = ({ children }) => {
  const [rolUser, setRolUser] = useState('');

  useEffect(() => {
    const storageRolUser = localStorage.getItem('userRol')
    if (storageRolUser) {
      setRolUser(JSON.parse(storageRolUser))
      //setRolUser(storageRolUser)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('userRol', JSON.stringify(rolUser));
  }, [rolUser])

  return (
    <userContext.Provider
      value={{
        rolUser,
        setRolUser
      }}
    >
      {children}
    </userContext.Provider>
  )
}

export default UserComponent