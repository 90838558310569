import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getAllTurnos } from "../services/horariosService";

const ModalInfoTurno = ({ showModal, hideModal }) => {

    const [arrTurnos, setArrTurnos] = useState([]);
    const [turno, setTurno] = useState('');
    const [resultTurno, setResultTurno] = useState('');

    useEffect(() => {
        (async () => {
            try {
                const res = await getAllTurnos();
                setArrTurnos(res.data);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const getTurnoById = (turno) => {
       // if (!turno) return '-'
        let aux = arrTurnos.find((e) => e.codigo === turno.toUpperCase());
        //return "(" + aux?.entrada + "-" + aux?.salida + ")"
        setResultTurno('El turno es de: '+ aux?.entrada+" -"+aux?.salida)
    };

    return (
        <Modal show={showModal} onHide={hideModal} centered>
            <Modal.Header closeButton>
            <h5>Detalles del turno</h5>
            </Modal.Header>
            <Modal.Body>
                <Form className="text-center mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Ingrese turno para obtener información"
                        className="mb-3"
                        value={turno}
                        onChange={(e) => { setTurno(e.target.value) }}
                    />
                    {/*<p className="pt-2 mb-1">El turno es de: {getTurnoById(turno)}</p>*/}
                    {resultTurno}
                    <Button className="btn form-control btn-secondary mt-3" onClick={() => {getTurnoById(turno)}}>Obtener información del turno</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalInfoTurno;