import http from "../http-common.js";

const loginClinica = (user, pass) => {
    return http.get(`/horarios/loginClinica?us=${user}&pw=${pass}`);
}

const getAllTurnos = () => {
    return http.get(`/horarios`);
}

const getAllHorariosByMonthAndYear = (searchMes, searchAnho) => {
    return http.get(`/horarios/getAllHorariosByMonthAndYear?mm=${searchMes}&yy=${searchAnho}`);
}

const getHorarioByDocument = (numDocumento, searchMes, searchAnho) => {
    return http.get(`/horarios/getHorarioByDocument?nd=${numDocumento}&mm=${searchMes}&yy=${searchAnho}`);
}

const getHorariosByDay = (daySearchDay, monthSearchDay, yearSearchDay) => {
    return http.get(`/horarios/getHorariosByDay?dd=${daySearchDay}&mm=${monthSearchDay}&yy=${yearSearchDay}`);
}

const deleteHorario = (id) => {
    return http.delete(`/horarios/deleteHorario/${id}`);
};

const getPersonalByDNI = (dni) => {
    return http.get(`/horarios/getPersonalByDNI?dni=${dni}`);
}

const changeTurno = (dia, turno, idHorario) => {
    return http.put(`/horarios/changeTurno?dd=${dia}&tt=${turno}&id=${idHorario}`);
}

const getEntradaHora = (searchMes, searchAnho) => {
    return http.get(`/horarios/getEntradaHora?mm=${searchMes}&yy=${searchAnho}`);
};

const getSoliHorasExtras = (day, month, year) => {
    return http.get(`/horarios/getSoliHorasExtras?dd=${day}&mm=${month}&yy=${year}`);
};
//CONTROL ENTRADA V2

/*const marcacionEntrada = (dni,diaActual,horaActual) => {
    return http.post(`/horarios/marcacionEntrada?id=${dni}&fe=${diaActual}&hr=${horaActual}`);
}*/

const marcacionEntrada = (dni,diaActual) => {
    return http.post(`/horarios/marcacionEntrada?id=${dni}&fe=${diaActual}`);
}
const getAllMarcacionesByDay = (day, month, year) => {
    return http.get(`/horarios/getAllMarcacionesByDay?dd=${day}&mm=${month}&yy=${year}`);
}

const getAllAprobacionesTurnos = (day, month, year) => {
    return http.get(`/horarios/getAllAprobacionesTurnos?dd=${day}&mm=${month}&yy=${year}`);
}

const aprobarHrsDias = (id, salida) => {
    return http.put(`/horarios/aprobarHrsDias?id=${id}&se=${salida}`);

}

const putRechazarHrsDias = (id) => {
    return http.put(`/horarios/rechazarHrsDias?id=${id}`);
}

const aprobarHrsExtras = (id) => {
    return http.put(`/horarios/aprobarHrsExtras?id=${id}`);
}

const putRechazarHrsExtras = (id) => {
    return http.put(`/horarios/rechazarHrsExtras?id=${id}`);
}

const getSolicitudById = (id) => {
    return http.get(`/horarios/validById?u=${id}`);
  }

  const acceptOrReject = (res, uid) => {
    return http.get(`/horarios/acceptOrReject?u=${uid}&r=${res}`);
  }

  const getSolicitudTurnoById = (id) => {
    return http.get(`/horarios/validTurnoById?u=${id}`);
  }

  const acceptOrRejectTurno = (res, uid) => {
    return http.get(`/horarios/acceptOrRejectTurno?u=${uid}&r=${res}`);
  }

export {
    getHorarioByDocument,
    getHorariosByDay,
    getAllHorariosByMonthAndYear,
    deleteHorario,
    getPersonalByDNI,
    getSolicitudById,
    acceptOrReject,
    getSolicitudTurnoById,
    acceptOrRejectTurno,
    changeTurno,
    loginClinica,
    marcacionEntrada,
    getAllMarcacionesByDay,
    getAllAprobacionesTurnos,
    aprobarHrsDias,
    putRechazarHrsDias,
    aprobarHrsExtras,
    putRechazarHrsExtras,
    getEntradaHora,
    getSoliHorasExtras,
    getAllTurnos,
};
