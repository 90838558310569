import React, { useContext, useEffect, useState } from "react";
import { Badge, Col, Container, Form, Modal, Row, Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil, faDownload, faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import { getAllHorariosByMonthAndYear, getHorarioByDocument, getAllMarcacionesByDay, getAllTurnos, changeTurno, deleteHorario } from "../services/horariosService";
import moment from "moment/moment";
import ModalTutorial from "../components/modalTutorial";
import { userContext } from "../context/userContext";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import CustomSelect from "../components/CustomSelect";
import ModalInfoTurno from "../components/ModalInfoTurno";
import Swal from "sweetalert2";

const Dashboard = () => {

    const dateSystem = moment();
    const monthDateSystem = dateSystem.format("M");
    const nameMonthDateSystem = dateSystem.format("MM");
    const yearDateSystem = dateSystem.format("YYYY");
    let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const yearMonth = yearDateSystem + "-" + nameMonthDateSystem;
    const [typeView, setTypeView] = useState("ViewDiaria");
    const [numDocumento, setNumDocumento] = useState('');
    const [arrData, setArrData] = useState([]);
    const [arrHorarios, setArrHorarios] = useState([]);
    const [arrTurnos, setArrTurnos] = useState([]);
    const [modalEdit, setModalEdit] = useState(false);
    const [turno, setTurno] = useState(0);
    const [dayToChange, setDayToChange] = useState();
    let dayLater = moment().add(1, 'days').format('YYYY-MM-DD');
    const [nomPersonalEdit, setNomPersonalEdit] = useState('');
    const [rowTurnoEdit, setRowTurnoEdit] = useState('');
    const [showModalInfoTurno, setShowModalInfoTurno] = useState(false);
    const handleClose = () => setShowModalInfoTurno(false);
    const handleShow = () => setShowModalInfoTurno(true);



    const [searchMes, setSearchMes] = useState(monthDateSystem);
    const [searchAnho, setSearchAnho] = useState(yearDateSystem);
    const [pageLoading, setPageLoading] = useState(true);
    const [monthAndYear, setMonthAndYear] = useState(yearMonth);
    const currentDate = moment().format("YYYY-MM-DD");
    const [filterDay, setFilterDay] = useState(currentDate);
    let daySearchDay = moment(filterDay).format("D");
    let monthSearchDay = moment(filterDay).format("MM");
    let yearSearchDay = moment(filterDay).format("YYYY");

    const handleOptionChange = ({ target }) => {
        setTypeView(target.value);
    };
    const getTurnoById = (id) => {
        if (!id) return '-'
        let aux = arrTurnos.find((e) => e.id === Number(id))
        //return aux?.codigo + "(" + aux?.entrada + "-" + aux?.salida + ")"
        return aux?.entrada + "-" + aux?.salida
    };

    useEffect(() => {
        (async () => {
            try {
                const res = await getAllMarcacionesByDay(daySearchDay, monthSearchDay, yearSearchDay);
                setArrData(res.data);
                const res2 = await getAllHorariosByMonthAndYear(meses[monthDateSystem - 1], yearDateSystem);
                setArrHorarios(res2.data);
                const res3 = await getAllTurnos();
                setArrTurnos(res3.data);
                setPageLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    //VISTA DIARIA
    const searchByDay = async (e) => {
        e.preventDefault();
        try {
            if (filterDay) {
                var diaFilterDay = moment(filterDay).format('D');
                var mesFilterDay = moment(filterDay).format('MM');
                var anioFilterDay = moment(filterDay).format('YYYY');
                const res = await getAllMarcacionesByDay(diaFilterDay, mesFilterDay, anioFilterDay);
                setArrData(res.data);
            } else {
                alert('Seleccione un día')
            }
        } catch (e) {
            console.log(e);
        }
    }

    //VISTA MENSUAL
    const handleMonthAndYearChange = (e) => {
        const selectedDate = e.target.value;
        const selectedYear = selectedDate.split("-")[0];
        const selectedMonth = selectedDate.split("-")[1];

        setSearchAnho(selectedYear);
        setSearchMes(selectedMonth);
        setMonthAndYear(selectedDate);
    };
    const searchByMonthAndYear = async (e) => {
        e.preventDefault();
        try {
            const res = await getAllHorariosByMonthAndYear(meses[searchMes - 1], searchAnho);
            setArrHorarios(res.data);
            setPageLoading(false);
            setNumDocumento('');
        } catch (e) {
            console.log(e);
        }
    }
    const searchByDNI = async (e) => {
        e.preventDefault();
        try {
            if (!numDocumento) {
                alert('Ingrese número de documento para buscar')
            } else {
                const res = await getHorarioByDocument(numDocumento, meses[searchMes - 1], searchAnho);
                setArrHorarios(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const showModalChangeTurn = (nomPersonal, id) => {
        setModalEdit(true);
        setNomPersonalEdit(nomPersonal);
        setRowTurnoEdit(id)
    }
    const handleCambiarTurno = async () => {
        let dia = moment(dayToChange).format('D');
        try {
            const resup = await changeTurno(dia, turno, rowTurnoEdit);
            if (resup) {
                const res = await getAllHorariosByMonthAndYear(meses[monthDateSystem - 1], yearDateSystem);
                setArrHorarios(res.data);
                setModalEdit(false);
            } else {
                alert('No se pudo actualizar')
            }
        } catch (error) {
            console.log(error)
        }
    }
    const closeModalEdit = () => {
        setModalEdit(false);
        setDayToChange();
        setTurno(0);
    }

    const exportToExcel = () => {
        const workbook = XLSX.utils.table_to_book(
            document.getElementById("miTabla")
        );
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, "horario.xlsx");
    };

    const opciones = arrTurnos.map((item) => {
        return { value: item.id, label: item.codigo + ' (' + item.entrada + '-' + item.salida + ')' }
    });

    const deleteRegistro = async (id) => {
        try {
            const confirmacion = await Swal.fire({
                title: "¿Estás seguro?",
                text: "Esta acción eliminará el registro",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Sí, eliminar",
                cancelButtonText: "Cancelar",
            });

            if (confirmacion.isConfirmed) {
                deleteHorario(id);
                // await obtenerHorarios();
                Swal.fire(
                    "Eliminado",
                    "El registro ha sido eliminado exitosamente",
                    "success"
                ).then(() => {
                    window.location.reload();
                });;
            }
        } catch (e) {
            console.log(e);
            Swal.fire("Error", "Hubo un error al eliminar el registro", "error");
        }
    };

    return (
        <Container fluid style={{ background: '#f4f7ff', height: '100vh' }} className="p-4">

            {/*<ModalTutorial/>*/}
            <h5>Gestión de horarios</h5>

            {/*SELECTOR DE VISTAS*/}
            <Row>
                <Col md={2}>
                    <Form.Check
                        type="radio"
                        label="Vista diaria"
                        value="ViewDiaria"
                        checked={typeView === "ViewDiaria"}
                        onChange={handleOptionChange}
                    //style={{ color: "#0D6EFD", fontWeight: "600" }}
                    />
                </Col>
                <Col md={2}>
                    <Form.Check
                        type="radio"
                        label="Vista mensual"
                        value="ViewMensual"
                        checked={typeView === "ViewMensual"}
                        onChange={handleOptionChange}
                    //style={{ color: "#0D6EFD", fontWeight: "600" }}
                    />
                </Col>
            </Row>

            {typeView === "ViewDiaria" &&
                <Row>
                    <Col>
                        <Form className="mb-3">
                            <Row>
                                <Col md={2}>
                                    <Form.Control
                                        onChange={(e) => { setFilterDay(e.target.value) }}
                                        value={filterDay}
                                        type="date"
                                        className="mb-3"
                                    />
                                </Col>
                                <Col md={3}>
                                    <button className="btn btn-primary" onClick={searchByDay}>Ver asistencias</button>
                                </Col>
                            </Row>
                        </Form>

                        {!pageLoading ?
                            <>
                                {arrData.length > 0 ?
                                    <>
                                        <Table responsive striped bordered hover className="text-center">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th>DNI</th>
                                                    <th>Cuidador</th>
                                                    <th>Entrada (TEMPUS)</th>
                                                    <th>Turno</th>
                                                    <th>Salida</th>
                                                    <th>Hrs. Extras</th>
                                                    <th>¿Aprobado?</th>
                                                    <th>Responsable</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {arrData.map((data, idx) => (
                                                    <tr key={idx}>
                                                        <td>{data.num_doc}</td>
                                                        <td>{data.nombre_personal}</td>
                                                        <td>{moment(data.hora).add(5, 'hours').format('lll')}</td>
                                                        <td>{data.entrada === null ? 'Sin horario teórico' : data.entrada + ' - ' + data.salida}</td>
                                                        <td>{data.fechahora === null ? 'Sin marcación' : moment(data.fechahora).add(5, 'hours').format('lll')}</td>
                                                        <td>{!data.horasExtras ? '-' : data.horasExtras}</td>
                                                        <td>{!data.estado ? '-' : <Badge bg={data.estado === 'Si' ? 'success' : data.estado === 'No' ? 'danger' : 'secondary'} className="text-capitalize">{data.estado}</Badge>}</td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                    : 'No hay registros'
                                }
                            </>
                            : 'Cargando asistencias...'}
                    </Col>
                </Row>
            }
            {typeView === "ViewMensual" &&
                <Row>
                    <Form>
                        <Row>
                            <Col md={2} xs={12}>
                                <Form.Control
                                    type="month"
                                    className="form-control mb-3"
                                    value={monthAndYear}
                                    onChange={handleMonthAndYearChange}
                                />
                            </Col>
                            <Col md={2} xs={12}>
                                <button
                                    className="btn btn-primary mb-3 form-control"
                                    onClick={searchByMonthAndYear}
                                >
                                    Ver horarios del mes
                                </button>
                            </Col>
                            {arrHorarios.length > 0 ?
                                <Col md={2} xs={12}>
                                    <button
                                        type="button"
                                        onClick={exportToExcel}
                                        className="btn btn-success form-control mb-3"
                                    >
                                        Descargar horario
                                        <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '12px' }} />
                                    </button>
                                </Col>
                                : ''}
                        </Row>
                    </Form>

                    <Col>
                        {arrHorarios.length > 0 ?
                            <>
                                <Form className="mb-3">
                                    <Row>
                                        <Col md={2} xs={12}>
                                            <Form.Control
                                                type="text"
                                                className="mb-3"
                                                placeholder="Número de documento"
                                                value={numDocumento}
                                                onChange={(e) => setNumDocumento(e.target.value)}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <button className="btn btn-primary form-control mb-3" onClick={searchByDNI}> Buscar por DNI </button>
                                        </Col>
                                        <Col>
                                            {/*BOTON INFO DEL TURNO*/}
                                            <Button className="btn btn-secondary" onClick={handleShow}><FontAwesomeIcon icon={faCircleInfo} /></Button>
                                            <ModalInfoTurno showModal={showModalInfoTurno} hideModal={handleClose} />
                                        </Col>
                                    </Row>
                                </Form>

                                <Table responsive striped bordered hover className="text-center" id="miTabla">
                                    <thead className="table-primary">
                                        <tr>
                                            <th>DNI</th>
                                            <th>Cuidador</th>
                                            <th>1</th>
                                            <th>2</th>
                                            <th>3</th>
                                            <th>4</th>
                                            <th>5</th>
                                            <th>6</th>
                                            <th>7</th>
                                            <th>8</th>
                                            <th>9</th>
                                            <th>10</th>
                                            <th>11</th>
                                            <th>12</th>
                                            <th>13</th>
                                            <th>14</th>
                                            <th>15</th>
                                            <th>16</th>
                                            <th>17</th>
                                            <th>18</th>
                                            <th>19</th>
                                            <th>20</th>
                                            <th>21</th>
                                            <th>22</th>
                                            <th>23</th>
                                            <th>24</th>
                                            <th>25</th>
                                            <th>26</th>
                                            <th>27</th>
                                            <th>28</th>
                                            <th>29</th>
                                            <th>30</th>
                                            <th>31</th>
                                            <th>Total de horas</th>
                                            <th>Hrs. Extras</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arrHorarios.map((data, idx) => (
                                            <tr key={idx}>
                                                <td>{data.num_doc_personal}</td>
                                                <td>{data.nombre_personal}</td>
                                                <td>{getTurnoById(data.dia1)}</td>
                                                <td>{getTurnoById(data.dia2)}</td>
                                                <td>{getTurnoById(data.dia3)}</td>
                                                <td>{getTurnoById(data.dia4)}</td>
                                                <td>{getTurnoById(data.dia5)}</td>
                                                <td>{getTurnoById(data.dia6)}</td>
                                                <td>{getTurnoById(data.dia7)}</td>
                                                <td>{getTurnoById(data.dia8)}</td>
                                                <td>{getTurnoById(data.dia9)}</td>
                                                <td>{getTurnoById(data.dia10)}</td>
                                                <td>{getTurnoById(data.dia11)}</td>
                                                <td>{getTurnoById(data.dia12)}</td>
                                                <td>{getTurnoById(data.dia13)}</td>
                                                <td>{getTurnoById(data.dia14)}</td>
                                                <td>{getTurnoById(data.dia15)}</td>
                                                <td>{getTurnoById(data.dia16)}</td>
                                                <td>{getTurnoById(data.dia17)}</td>
                                                <td>{getTurnoById(data.dia18)}</td>
                                                <td>{getTurnoById(data.dia19)}</td>
                                                <td>{getTurnoById(data.dia20)}</td>
                                                <td>{getTurnoById(data.dia21)}</td>
                                                <td>{getTurnoById(data.dia22)}</td>
                                                <td>{getTurnoById(data.dia23)}</td>
                                                <td>{getTurnoById(data.dia24)}</td>
                                                <td>{getTurnoById(data.dia25)}</td>
                                                <td>{getTurnoById(data.dia26)}</td>
                                                <td>{getTurnoById(data.dia27)}</td>
                                                <td>{getTurnoById(data.dia28)}</td>
                                                <td>{getTurnoById(data.dia29)}</td>
                                                <td>{getTurnoById(data.dia30)}</td>
                                                <td>{getTurnoById(data.dia31)}</td>
                                                <td style={{ background: data.horas_totales >= 0 ? 'red' : '' }}>{data.horas_totales}</td>
                                                <td>{!data.totalHorasExtras ? '-' : data.totalHorasExtras + ' hrs'}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-success"
                                                        onClick={() => { showModalChangeTurn(data.nombre_personal, data.id) }}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Modal show={modalEdit} size="md" centered>
                                    <Modal.Header>
                                        <Modal.Title>Editar turno de {nomPersonalEdit}</Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row className="mb-2">
                                            <Form.Group>
                                                <Form.Control
                                                    type="date"
                                                    min={dayLater}
                                                    className="form-control"
                                                    value={dayToChange}
                                                    onChange={(e) => { setDayToChange(e.target.value); }}
                                                    required
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CustomSelect options={opciones} onOptionSelect={(selectedOption) => setTurno(selectedOption.value)} className='form-select' />
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className="btn btn-success" onClick={handleCambiarTurno}>Actualizar</button>
                                        <button className="btn btn-secondary" onClick={closeModalEdit} >Cancelar</button>
                                    </Modal.Footer>
                                </Modal>
                            </> : 'Sin Resultados'
                        }
                    </Col>
                </Row>
            }

        </Container >
    )
}

export default Dashboard;