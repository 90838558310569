import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import logo from "../img/logo-clinica.png";
import { Button, Card, Col, Container, FormControl, Image, Row, Spinner } from "react-bootstrap";
import { getSolicitudById, acceptOrReject } from '../services/horariosService'

export const Confirmacion = () => {
  const [showRejectDetail, setShowRejectDetail] = useState(false);
  const [arrSolicitud, setArrSolicitud] = useState([]);
  const [loadPage, setLoadPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [payReq, setPayReq] = useState(false);
  const [obs, setObs] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const uidSolicitud = searchParams.get("p");

  const accept = async () => {
    setIsLoading(true);
    try {
      const res = await acceptOrReject("Si", uidSolicitud);
      if (res.status === 200) {
        setCompleted(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const reject = async () => {
    setIsLoading(true);
    try {
      const res = await acceptOrReject("No", uidSolicitud);
      if (res.status === 200) {
        setCompleted(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (uidSolicitud) {
          const res = await getSolicitudById(uidSolicitud);
          setArrSolicitud(res.data);
          if (res.data.length > 0 && res.data[0].montoPago) setPayReq(true);
        }
        setLoadPage(true);
      } catch (error) {
        setLoadPage(true);
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid style={{ background: "#f4f7ff", height: "100vh" }}>
      <Row style={{ justifyContent: "center", alignItems: "center", height: "100%" }}
      >
        <Col md={5}>
          <Card style={{ width: '420px' }}>
            <Card.Body className="pb-4 px-4">
              {loadPage && (
                <>
                  <Row className="justify-content-center mb-4">
                    <Col md={8} className="text-center">
                      <Image alt="logo-clinica" src={logo} className="img-fluid" />
                    </Col>
                  </Row>
                  <Card.Title className="text-center"><b>Confimar Horas Extras</b></Card.Title>
                  {arrSolicitud.length <= 0 ? (
                    <div className="text-center">
                      <p> Esta solicitud no se encuentra disponible</p>
                      <Link to="/" className="form-control mb-3 btn btn-primary">P&aacute;gina de inicio</Link>
                    </div>
                  ) : completed ? (
                    <div className="text-center">
                      <p>Listo, tu decision fue registrada. Gracias por tu tiempo</p>
                    </div>
                  ) : (
                    <>
                      <div className="my-4 text-center">
                        <p>El personal {arrSolicitud[0].nombre_personal}, hará tiempo extra: <br />{arrSolicitud[0].horasExtras} {arrSolicitud[0].horasExtras > 0 ? (arrSolicitud[0].horasExtras > 1 ? "Horas" : " Hora") : ""}.</p>
                        <p>¿Estás de acuerdo?</p>
                      </div>
                      {isLoading ? <Spinner as="span" animation="border" variant="light" role="status" aria-hidden="true" /> : <Row>
                        <Col className="text-center">
                          <Button variant="success" className="mx-1" onClick={accept} disabled={isLoading}>Acepto</Button>
                        </Col>
                        <Col className="text-center">
                          <Button variant="danger" className="mx-1" onClick={reject} disabled={isLoading}>Rechazo</Button>
                        </Col>
                      </Row>}
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}