import React, { useState } from 'react';

const CustomSelect = ({ options, onOptionSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInputChange = event => {
        setSearchTerm(event.target.value);
        setIsOpen(true);
    };

    const handleOptionClick = option => {
        setSelectedOption(option);
        setSearchTerm('');
        setIsOpen(false);

        onOptionSelect(option);
    };

    return (
        <div className="custom-select">
        <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder={selectedOption?.label || "Buscar un nuevo turno..."}
            className='custom-input'
        />
        {isOpen && (
            <ul className="options">
            {filteredOptions.map(option => (
        
                <li key={option.value} onClick={() => handleOptionClick(option)}>
                {option.label}
                </li>
            ))}
            </ul>
        )}
        {selectedOption && <p>Opción seleccionada: {selectedOption.value}</p>}
        </div>
    );
};

export default CustomSelect;
